<template>
    <div id="app">
        <headers :tabactive="tabactive" v-if="showpase" />
        <router-view></router-view>
        <footers v-if="showpase" />
        <!-- <service /> -->
        <div class="cssloader" v-if="!showpase">
            <div class="contentcssloader">
                <div class="cssloadersh1"></div>
                <div class="cssloadersh2"></div>
                <h4 class="cssloaderlt">loading</h4>
            </div>
        </div>
    </div>
</template>
<script>
import headers from '@/components/headers';
import footers from "@/components/footer";
// import service from "@/components/service";
export default {
    name: 'App',
    components: {
        headers,
        footers,
        // service
    },
    data() {
        return {
            isLoaded: null,
            showpase: false,
            tabactive: null,
        };
    },
    methods: {
    },
    created() {
        this.handleResize()
        this.$router.afterEach((to, from) => {
            if (to) {
                this.showpase = true
            }
            const list = this.$router.options.routes.map(rel => rel.path);
            const his = [list[1], list[2], list[7], list[8]];
            const currentRoute = to.path;

            // 检查历史路径
            his.forEach((path, index) => {

                if (path === currentRoute) {
                    this.tabactive = index;
                }
            });
            // 检查二级子路径
            const validPaths = ['/dasolution', '/damainsolution', '/swimsolution', '/imsolution', '/gmsolution'];
            if (validPaths.includes(currentRoute)) {
                this.tabactive = 1;
            }
        });
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            const baseSize = 100; // 设计稿宽度的1/100
            const baseWidth = 1920; // 设计稿的宽度，例如1920px
            const clientWidth = document.documentElement.clientWidth || window.innerWidth;
            const rem = (clientWidth / baseWidth) * baseSize;
            document.documentElement.style.fontSize = `${rem}px`;
            /* 
                 this.$nextTick(() => {
                     // 滚动到之前的位置
                     window.scrollTo(0, 1);
                 });
            */
        },
    }
}
</script>
<style lang="scss">
@import "../public/font-css/main.css";
@function rem($px) {
    $base-font-size: 100; // 根据设计稿的基准字体大小设置
    @return $px / $base-font-size * 1rem;
}
.card_img {
    img {
        width: 0.9rem;
        height: 0.9rem;
    }
}
@import url("https://fonts.googleapis.com/css?family=Roboto:100");
#app {
    position: relative;
}
.cssloader {
    background: #333; /* 背景色 */
    width: 100%; /* 宽度 100% 覆盖整个屏幕 */
    height: 100vh; /* 高度 100vh 覆盖整个视口高度 */
    position: fixed; /* 使用 fixed 定位，使其覆盖整个屏幕 */
    top: 0; /* 顶部对齐视口 */
    left: 0; /* 左侧对齐视口 */
    z-index: 9999; /* 确保该元素位于最上层 */
    display: flex;
    align-items: center;
    justify-content: center;
}

/** loader **/
.cssloadersh1 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 0;
    border-color: #354952 transparent transparent transparent;
    margin: 0 auto;
    animation: shk1 1s ease-in-out infinite normal;
}

.cssloadersh2 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 50px 50px;
    border-color: transparent transparent #df1a54 transparent;
    margin: -50px auto 0;
    animation: shk2 1s ease-in-out infinite alternate;
}

/** animation starts here **/
@keyframes shk1 {
    0% {
        transform: rotate(-360deg);
    }

    100% {
    }
}

@keyframes shk2 {
    0% {
        transform: rotate(360deg);
    }
    100% {
    }
}

.cssloaderlt {
    color: #fff;
    font-family: "Roboto", sans-serif;
    margin: 30px auto;
    text-align: center;
    font-weight: 100;
    letter-spacing: 10px;
}
</style>