const state = {
    scrollTop: 0,
    clientWidth: 0,
    dialogVisible: false
}
const mutations = {
    // 获取滚动条距离
    GET_SCROLL_TOP(state) {
        state.scrollTop = window.scrollY || document.documentElement.scrollTop;
    },
    // 获取滚动条距离
    GET_CLIENT_WIDTH(state) {
        state.clientWidth = window.innerWidth || document.documentElement.clientWidth;
    },
}
const actions = {
    // getScrollTop({commit}){

    // },
    // getClientWidth(){

    // }
}
const getters = {
}
export default {
    // namespaced: true,
    state,
    mutations,
    actions,
    getters
}